html {
    height: 100%;
    touch-action: manipulation;
}
  
html,
body,
app-root {
    // overflow-x: hidden;
    height: 100%;
}

:focus {
    outline: none;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    color: #1e130d;
}