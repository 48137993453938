.font-size-sm {
    font-size: $font-size-sm;
}

.font-size-base {
    font-size: $font-size-base;
}

.font-size-lg {
    font-size: $font-size-lg;
}

.cursor-pointer {
    cursor: pointer;
}

.fw-semibold {
    font-weight: 500;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat-x {
    background-repeat: repeat-x;
}

.bg-repeat-y {
    background-repeat: repeat-y;
}

.bg-size-cover {
    background-size: cover;
}

.bg-size-contain {
    background-size: contain;
}

th.active{
    background-color: rgb(0 197 105 / 40%);    
}
.dias{
    text-align: center;
    font-size: 9px;
}
.lista .form-check-input{
    margin: 0 auto;
    display: block;
}
.lista .form-check-input:hover{
    cursor: pointer!important;
}
.lista .form-check-input:checked {
    background-color: #00c569;
    border-color: #00c569;
}
.lista tbody tr:hover{
    background-color: rgba(244, 99, 99, 0.25);
}

.lista tbody tr td.active{
    background-color: rgb(0 197 105 / 40%);
}
.lista th, .lista tr{
    border-color: #a7c1d2;
}
.lista {
    font-size: 12px;
}
.lista .table > :not(caption) > * > * {
    padding: 0.35rem 0.35rem;
}
.disable{
    background-color: #ffedbd!important;
}
.page-header{
    text-transform: capitalize;
}